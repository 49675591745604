import axios from "axios";
const apiURL = "http://elit3-api.areinhardt.eu/";

class DataService {
  async login(userInfo) {
    let formData = new FormData();
    formData.append("action", "login");
    formData.append("username", userInfo.user.username);
    formData.append("password", userInfo.user.password);
    const response = await axios.post(apiURL + "User/", formData);
    console.log(response.data);
    if (!response.data.success) {
      return "Error";
    } else {
      userInfo.user.id = response.data.id;
      if (userInfo.stayLoggedIn) {
        localStorage.setItem("user", JSON.stringify(userInfo.user));
      } else {
        sessionStorage.setItem("user", JSON.stringify(userInfo.user));
      }
      return userInfo.user;
    }
  }
  logout() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
  }
  async register(newUser) {
    let formData = new FormData();
    formData.append("action", "create");
    formData.append("username", newUser.username);
    formData.append("password", newUser.password);
    formData.append("email", newUser.email);
    formData.append(
      "verification_callback",
      "http://elit3.areinhardt.eu/#/Verification/"
    );
    const response = await axios.post(apiURL + "User/", formData);
    return response.data.success;
  }
  checkLoggedIn() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user === null) {
      user = JSON.parse(sessionStorage.getItem("user"));
    }
    if (user === null) {
      return false;
    } else {
      return true;
    }
  }
  getLoggedInUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user === null) {
      user = JSON.parse(sessionStorage.getItem("user"));
    }
    return user;
  }
  async makeDeposit(data) {
    let formData = new FormData();
    formData.append("action", "create");
    formData.append("user_id", data.userID);
    formData.append("amount", data.amount);
    const response = await axios.post(apiURL + "Deposit/", formData);
    return response.data.balance_after;
  }

  async getBalanceOfUser(userId) {
    let formData = new FormData();
    formData.append("action", "balance");
    formData.append("id", userId);
    const response = await axios.post(apiURL + "User/", formData);
    console.log("balance: " + response.data.balance);
    return response.data.balance;
  }

  async makeWithdrawal(data) {
    let formData = new FormData();
    formData.append("action", "create");
    formData.append("user_id", data.userID);
    formData.append("amount", data.amount);
    const response = await axios.post(apiURL + "Withdrawal/", formData);
    return response.data.balance_after;
  }

  async playCoinflip(data) {
    let formData = new FormData();
    formData.append("action", "create");
    formData.append("user_id", data.userID);
    formData.append("amount", data.einsatz);
    formData.append("bet_face", data.bet_face);
    const response = await axios.post(apiURL + "Coinflip/", formData);
    return response.data;
  }

  async playLuckywheel(userid, einsatz) {
    let formData = new FormData();
    formData.append("action", "create");
    formData.append("user_id", userid);
    formData.append("amount", einsatz);
    console.log(einsatz);
    const response = await axios.post(apiURL + "Luckywheel/", formData);
    return response.data;
  }

  async playRoulette(bets, userid) {
    let formData = new FormData();
    formData.append("action", "create");
    formData.append("user_id", userid);
    formData.append("bets", bets);
    const response = await axios.post(apiURL + "Roulette/", formData);
    return response.data;
  }

  async requestPassword(username) {
    let formData = new FormData();
    formData.append("action", "requestPassword");
    formData.append("username", username);
    formData.append(
      "request_callback",
      "http://elit3-api.areinhardt.eu/reset.php"
    );
    const response = await axios.post(apiURL + "User/", formData);
    return response.data;
  }

  async verifyAccount(userid, vCode) {
    let formData = new FormData();
    formData.append("action", "verify");
    formData.append("id", userid);
    formData.append("verification_code", vCode);
    const response = await axios.post(apiURL + "User/", formData);
    return response.data;
  }
}
export default new DataService();

//Beispiele für AXIOS Get und Post:

// getTakenModules() {
//   return axios.get(
//     apiURL + "Module/GetTakenModules",
//     {
//       headers: authHeader(),
//     }
//   );
// }
// async takeModule(moduleId) {
//   const response = await axios.post(
//     apiURL + "Module/TakeModule",
//     null,
//     {
//       params: {
//         VorlesungsID: moduleId,
//       },
//       headers: authHeader(),
//     }
//   );
//   return response.data;
// }
// async getQuestionsFromUnits(unitIDs) {
//   const response = await axios.post(
//     apiURL + "Learning/GetQuestion",
//     unitIDs,
//     {
//       headers: authHeader(),
//     }
//   );
//   return response.data;
// }
// async postAnswerLearning(answer) {
//   const response = await axios.post(
//     apiURL + "Learning/SendAnswer",
//     answer,
//     {
//       headers: authHeader(),
//     }
//   );
//   return response.data;
// }
