<template>
  <div id="nav">
    <div class="wallpaper-login"></div>
    <b-navbar type="dark">
      <b-navbar-brand style="cursor: pointer" @click="refresh">
        <img
          src="../assets/casinoPicHeader.png"
          alt="Casino Picture"
          class="headerPicture"
        />
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-navbar-brand id="walletBalance" class="wallet" v-if="loggedIn">
          Wallet: {{ balance }} €
        </b-navbar-brand>
        <b-nav-item class="navBarRightSide" v-if="!loggedIn">
          <router-link to="/Login"
            ><b-button variant="warning" size="lg">Login</b-button></router-link
          ></b-nav-item
        >

        <b-nav-item-dropdown
          class="navBarRightSide"
          v-else
          id="profileDropdown"
          toggle-class="text-light"
          right
        >
          <template slot="button-content">
            <b-icon style="margin-right: 4px" icon="person-fill"></b-icon
            >{{ currentUser.username }}
          </template>
          <b-dropdown-item @click="goToWallet"
            >Wallet: {{ balance }} €</b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import DataService from "../services/data.service";
export default {
  name: "Navbar",
  data() {
    return {
      balance: 0,
    };
  },
  methods: {
    logout() {
      this.loggedIn = false;
      DataService.logout();
      window.location.reload();
      this.$router.push("/Login");
    },
    goToWallet() {
      this.$router.push("/Wallet");
    },
    refreshWallet() {
      this.$root.$on("navBar", (data) => {
        this.balance = data;
      });
    },
    refresh() {
      this.$router.push("/Home");
      window.location.reload();
    },
  },
  created() {
    if (this.currentUser) {
      this.refreshWallet();
      DataService.getBalanceOfUser(this.currentUser.id).then((response) => {
        this.balance = response;
      });
    }
  },
  computed: {
    loggedIn() {
      return DataService.checkLoggedIn();
    },
    currentUser() {
      return DataService.getLoggedInUser();
    },
  },
};
</script>

<style>
.wallet {
  font-size: 1.7rem !important;
  text-shadow: 2px 2px red;
}

#profileDropdown {
  font-size: 22px;
}

.navBarRightSide {
  margin-right: 10%;
}

.wallpaper-login {
  background: url("../assets/testBackground.webp") no-repeat center center fixed;
  background-size: cover;
  background-position: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.headerPicture {
  width: 100%;
  max-width: 500px;
}
</style>
