import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/WheelOfFortune",
    name: "WheelOfFortune",
    component: () => import("../views/WheelOfFortune.vue"),
  },
  {
    path: "/Roulette",
    name: "Roulette",
    component: () => import("../views/Roulette.vue"),
  },
  {
    path: "/CoinFlip",
    name: "CoinFlip",
    component: () => import("../views/CoinFlip.vue"),
  },
  {
    path: "/Wallet",
    name: "Wallet",
    component: () => import("../views/Wallet.vue"),
  },
  {
    path: "/Verification",
    name: "Verification",
    component: () => import("../views/Verification.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
