<template>
  <div id="home">
    <h1>Willkommen im ELIT3 Casino!</h1>
    <h5>Wählen Sie ein Spiel aus:</h5>
    <b-card-group deck class="cardGroup">
      <b-card
        title="Glücksrad"
        :img-src="require('../assets/wheeloffortune.jpg')"
        bg-variant="dark"
        text-variant="white"
        footer-bg-variant="dark"
        footer-border-variant="white"
        border-variant="white"
        class="cardClass"
      >
        <b-card-text>
          Drehe dich schnell und einfach ins Glück mit unserem Glücksrad!
        </b-card-text>
        <template #footer>
          <b-button
            variant="warning"
            class="zumSpielBtn"
            @click="goToGame('/WheelOfFortune')"
            >Glücksrad spielen<b-icon-arrow-right-short
              style="margin-left: 2px"
            ></b-icon-arrow-right-short
          ></b-button>
        </template>
      </b-card>
      <b-card
        title="Roulette"
        :img-src="require('../assets/roulette.webp')"
        img-top
        bg-variant="dark"
        text-variant="white"
        footer-bg-variant="dark"
        footer-border-variant="white"
        border-variant="white"
        class="cardClass middleCard"
      >
        <b-card-text>
          Werde Gewinner in einem der beliebtesten Casino Spiele Roulette!
        </b-card-text>
        <template #footer>
          <b-button
            variant="warning"
            class="zumSpielBtn"
            @click="goToGame('/Roulette')"
            >Roulette spielen<b-icon-arrow-right-short
              style="margin-left: 2px"
            ></b-icon-arrow-right-short>
          </b-button>
        </template>
      </b-card>
      <b-card
        title="Coin Flip"
        :img-src="require('../assets/coinflip.jpg')"
        img-top
        bg-variant="dark"
        text-variant="white"
        footer-bg-variant="dark"
        footer-border-variant="white"
        border-variant="white"
        class="cardClass"
      >
        <b-card-text>
          Kopf oder Zahl? Bei unserem Coin Flip Spiel liegt es in deiner Hand!
        </b-card-text>
        <template #footer>
          <b-button
            variant="warning"
            class="zumSpielBtn"
            @click="goToGame('/CoinFlip')"
            >Coin Flip spielen<b-icon-arrow-right-short
              style="margin-left: 2px"
            ></b-icon-arrow-right-short
          ></b-button>
        </template>
      </b-card>
    </b-card-group>
    <b-modal
      id="errorModal"
      centered
      body-bg-variant="warning"
      body-text-variant="dark"
      hide-footer
      hide-header
      ><div style="margin: 40px; font-size: 1.5rem">
        <center>Sie müssen angemeldet sein um zu spielen!</center>
      </div>
      <div>
        <router-link to="/Login">
          <center>
            <b-button
              style="
                margin-bottom: 20px;
                padding: 10px 20px 10px 20px;
                font-size: 1.3rem;
              "
              variant="dark"
            >
              Zum Login
            </b-button>
          </center>
        </router-link>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DataService from "../services/data.service";
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  methods: {
    goToGame(param) {
      if (!DataService.checkLoggedIn()) {
        this.$bvModal.show("errorModal");
      } else {
        this.$router.push(param);
      }
    },
  },
};
</script>

<style scoped>
#home {
  margin-top: 10px;
  overflow: hidden;
}

.routerLinks {
  text-decoration: none;
}

.cardClass {
  max-width: 20rem;
  max-height: 35rem;
  cursor: pointer;
  text-align: center;
}

.middleCard {
  margin: 0px 60px 0px 60px !important;
}

.cardGroup {
  display: flex;
  justify-content: center;
}

.zumSpielBtn {
  width: 100%;
}

.zumSpielBtn:hover {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

h1 {
  margin-top: 20px;
  text-align: center;
  color: white;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  -webkit-animation: glow 1.5s ease-in-out infinite alternate;
  -moz-animation: glow 1.5s ease-in-out infinite alternate;
  animation: glow 1.5s ease-in-out infinite alternate;
}

h5 {
  text-align: center;
  color: white;
  margin-bottom: 30px;
  margin-top: 40px;
}

@-webkit-keyframes glow {
  from {
    color: #fff;
    text-shadow: 0 0 3px #910c02, 0 0 9px #910c02, 0 0 15px #910c02,
      0 0 20px #910c02, 0 0 25px #910c02, 0 0 30px #910c02, 0 0 35px #910c02,
      0 0 40px #910c02;
  }

  to {
    color: white;
    text-shadow: 0 0 3px #910c02, 0 0 9px #910c02, 0 0 15px #910c02,
      0 0 20px #910c02, 0 0 25px #910c02, 0 0 30px #910c02, 0 0 35px #910c02,
      0 1 40px #910c02;
  }
}
</style>
